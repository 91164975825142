$(function () {
    var $partners = $('.box-partners .swiper-container');

    if(!$partners.length) return

    var mySwiper = new Swiper ($partners, {
        slidesPerView: 4,
        spaceBetween: 30,
        autoplay: {
            delay: 3000,
        },
        speed: 550,
        navigation: {
            prevEl: $partners.parent().find('.button-prev'),
            nextEl: $partners.parent().find('.button-next'),
        },
        breakpoints: {
            1279: {
                slidesPerView: 3
            },
            991: {
                slidesPerView: 2
            },
            767: {
                slidesPerView: 1
            }
        }
    });

    $partners.mouseover(function() {
        mySwiper.autoplay.stop();
    }).mouseout(function() {
        mySwiper.autoplay.start();
    });
});