$(function () {
    var $lookbook = $('.home-lookbook .swiper-container');

    if(!$lookbook.length) return

    var mySwiper = new Swiper ($lookbook, {
        slidesPerView: 4,
        spaceBetween: 2,
        autoplay: {
            delay: 3000,
        },
        speed: 550,
        navigation: {
            prevEl: $('.lookbook-navigation .button-prev'),
            nextEl: $('.lookbook-navigation .button-next'),
        },
        breakpoints: {
            1199: {
                slidesPerView: 3
            },
            767: {
                slidesPerView: 2
            },
            575: {
                slidesPerView: 1
            }
        }
    });

    $lookbook.mouseover(function() {
        mySwiper.autoplay.stop();
    }).mouseout(function() {
        mySwiper.autoplay.start();
    });
});