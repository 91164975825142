$(function () {
    var $galleryCampaing = $('.page-campaign .page-gallery .swiper-container');

    if(!$galleryCampaing.length) return

    var mySwiper = new Swiper ($galleryCampaing, {
        slidesPerView: 3,
        spaceBetween: 30,
        autoplay: {
            delay: 3000,
        },
        speed: 550,
        navigation: {
            prevEl: $('.gallery-navigation .button-prev'),
            nextEl: $('.gallery-navigation .button-next'),
        },
        breakpoints: {
            767: {
                slidesPerView: 2
            },
            575: {
                slidesPerView: 1
            }
        }
    });

    $galleryCampaing.mouseover(function() {
        mySwiper.autoplay.stop();
    }).mouseout(function() {
        mySwiper.autoplay.start();
    });
});