$(function() {
    var headerHeight = $('.site-header').innerHeight() + 4;
    var $headerFixed = $('.site-header-fixed');
    var firstWindowPos = $(window).scrollTop();

    if(firstWindowPos > headerHeight) {
        if ($(this).scrollTop() > headerHeight) {
            $headerFixed.addClass("show");
        } else {
            $headerFixed.removeClass("show");
        }
    }

    $(window).scroll(function () {
        if ($(this).scrollTop() > headerHeight) {
            $headerFixed.addClass("show");
        } else {
            $headerFixed.removeClass("show");
        }
    });
});