$(function() {
    // Custom Validate E-mail
    $.validator.addMethod("validaEmail", function(value, element) {
        var exclude=/[^@\-\.\w]|^[_@\.\-]|[\._\-]{2}|[@\.]{2}|(@)[^@]*\1/;
        var check=/@[\w\-]+\./;
        var checkend=/\.[a-zA-Z]{2,3}$/;
        if(((value.search(exclude) != -1)||(value.search(check)) == -1)||(value.search(checkend) == -1)){return false;}
        else {return true;}
    }, 'Por favor, informe um E-mail válido!');

    // Mask Phone
    var SPMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    spOptions = {
        onKeyPress: function (val, e, field, options) {
            field.mask(SPMaskBehavior.apply({}, arguments), options);
        }
    };

    $('input[type="tel"]').mask(SPMaskBehavior, spOptions);
});

// Form Contato
$(function() {
    $formContact = $('#form_contato');

    if(!$formContact.length) return

    $formButton = $formContact.find('.button');
    
    $formButton.click(function(e) {
    
        e.preventDefault();
    
        var form        = $formContact;
        var button      = $(this);
    
        form.validate({
            errorElement: 'span',

            rules: {
                nome: {
                    required: true,
                    minlength: 3,
                    maxlength: 40
                },
                email: {
                    required: true,
                    validaEmail: true
                },
                assunto: {
                    required: true
                },
                mensagem: {
                    required: true
                }
            },

            messages: {
                nome: {
                    required: 'Por favor, informe seu Nome!',
                    minlength: 'Digite pelo menos {0} caracteres.',
                    maxlength: 'Tamanho máximo de {0} caracteres.'
                },
                telefone: {
                    required: 'Por favor, informe seu Telefone!',
                    minlength: 'Por favor, informe um Telefone válido!'
                },
                assunto: {
                    required:'Por favor, informe o Assunto!'
                },
                mensagem: {
                    required: 'Por favor, informe sua Mensagem!',
                    maxlength: 'Tamanho máximo de {0} caracteres.'
                }
            }
        });
    
        if(form.valid() === true) {
            // Ajax para enviar o formulário
            
            return
        }
    });
});

// Form Contato Lojista
$(function() {
    $formContatoLojista = $('#form_contato_lojista');

    if(!$formContatoLojista.length) return

    $formButton = $formContatoLojista.find('.button');
    
    $formButton.click(function(e) {
    
        e.preventDefault();
    
        var form        = $formContatoLojista;
        var button      = $(this);
    
        form.validate({
            errorElement: 'span',

            rules: {
                nome: {
                    required: true,
                    minlength: 3,
                    maxlength: 40
                },
                email: {
                    required: true,
                    validaEmail: true
                },
                mensagem: {
                    required: true
                }
            },

            messages: {
                nome: {
                    required: 'Por favor, informe seu Nome!',
                    minlength: 'Digite pelo menos {0} caracteres.',
                    maxlength: 'Tamanho máximo de {0} caracteres.'
                },
                email: 'Por favor, informe um E-mail válido!',
                mensagem: {
                    required: 'Por favor, informe sua Mensagem!',
                    maxlength: 'Tamanho máximo de {0} caracteres.'
                }
            }
        });
    
        if(form.valid() === true) {
            // Ajax para enviar o formulário
            
            return
        }
    });
});

// Form Orcamento Lojista
$(function() {
    $formOrcamentoLojista = $('#form_orcamento_lojista');

    if(!$formOrcamentoLojista.length) return

    $formButton = $formOrcamentoLojista.find('.button');
    
    $formButton.click(function(e) {
    
        e.preventDefault();
    
        var form        = $formOrcamentoLojista;
        var button      = $(this);
    
        form.validate({
            errorElement: 'span',

            rules: {
                nome: {
                    required: true,
                    minlength: 3,
                    maxlength: 40
                },
                email: {
                    required: true,
                    validaEmail: true
                },
                telefone: {
                    required: true,
                    minlength: 14
                },
                codigo_prod: {
                    required: true
                }
            },

            messages: {
                nome: {
                    required: 'Por favor, informe seu Nome!',
                    minlength: 'Digite pelo menos {0} caracteres.',
                    maxlength: 'Tamanho máximo de {0} caracteres.'
                },
                email: 'Por favor, informe um E-mail válido!',
                telefone: {
                    required: 'Por favor, informe seu Telefone!',
                    minlength: 'Por favor, informe um Telefone válido!'
                },
                codigo_prod: {
                    required:'Por favor, informe o(s) código(s)!'
                },
                mensagem: {
                    required: 'Por favor, informe sua Mensagem!',
                    maxlength: 'Tamanho máximo de {0} caracteres.'
                }
            }
        });
    
        if(form.valid() === true) {
            // Ajax para enviar o formulário
            
            return
        }
    });
});