$(function() {
    var $btnShowMenu = $('.btn-toggle-menu');
    var $btnCloseMenu = $('.btn-close-menu');

    $btnShowMenu.on('click', function(e) {

        e.preventDefault();

        var $this = $(this);

        $('.site-header-mobile').addClass('show');
    });

    $btnCloseMenu.on('click', function(e) {

        e.preventDefault();
        
        $('.site-header-mobile').removeClass('show');
    });
});