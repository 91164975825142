$(function () {
    var $galleryAbout = $('.box-about-gallery .swiper-container');

    if(!$galleryAbout.length) return

    var mySwiper = new Swiper ($galleryAbout, {
        slidesPerView: 2,
        spaceBetween: 15,
        autoplay: {
            delay: 3000,
        },
        speed: 550,
        navigation: {
            prevEl: $galleryAbout.parent().find('.button-prev'),
            nextEl: $galleryAbout.parent().find('.button-next'),
        },
        breakpoints: {
            991: {
                slidesPerView: 2
            },
            767: {
                slidesPerView: 1
            }
        }
    });

    $galleryAbout.mouseover(function() {
        mySwiper.autoplay.stop();
    }).mouseout(function() {
        mySwiper.autoplay.start();
    });
});