$(function() {
    $bgHeader = $('[data-bg]');

    if(!$bgHeader.length) return

    // Slideshow
    $bgHeader.each(function() {
        var item = $(this);
        var image = $(item).data('bg');

        $(item).css({
            'backgroundImage': "url("+image+")"
        });
    });
});