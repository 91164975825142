$(function () {
    function isScrolledIntoView(elem) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();

        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).innerHeight();

        return ((elemTop <= docViewBottom) && (elemBottom >= docViewTop));
    }

    
    $(window).scroll(function(){
        var isElementInView = isScrolledIntoView($('.site-footer'));
    
        if(isElementInView) {
            $('.box-whatsapp.fixed').addClass('disable');
        } else {
            $('.box-whatsapp.fixed').removeClass('disable');
        }
     })
});